@import url('https://fonts.googleapis.com/css2?family=Baloo+Thambi+2&family=Roboto+Mono:wght@300&display=swap');

.modalBackground {
    width: auto;
    height: auto;
    background-color: rgba(200, 200, 200);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;

  }
  
  .modalContainer10 {
    width: 350px;
    height: 350px;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
    font-family: 'Roboto Mono', monospace;
  }
  
  .modalContainer10 .title {
    display: inline-block;
    text-align: center;
    margin-top: 5px;
    color: cadetblue;
  }
  
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
  }
  
  .titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
  }
  
  .modalContainer10 .body7 {
    flex: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    text-align: center;
   
    gap: 1rem;
  }
  .body-part-btn{
    display: flex;
    gap :1rem;
  }
  .btnn1{
    width: 6vw;
    height: 7vh;
    background-color: silver ;
    color: black;
    text-align: center;
    border: none;
    font-family: 'Baloo Thambi 2', cursive;
    font-size: 1rem;
}



 