@import url(https://fonts.googleapis.com/css2?family=Baloo+Thambi+2&family=Josefin+Sans:wght@300&family=Roboto+Mono:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Changa:wght@300&family=Inter:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Changa:wght@300&family=Inter:wght@500&family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Baloo+Thambi+2&family=Roboto+Mono:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Baloo+Thambi+2&family=Roboto+Mono:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Baloo+Thambi+2&family=Roboto+Mono:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Baloo+Thambi+2&family=Josefin+Sans:wght@300&family=Roboto+Mono:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Baloo+Thambi+2&family=Roboto+Mono:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Baloo+Thambi+2&family=Roboto+Mono:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Baloo+Thambi+2&family=Roboto+Mono:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Baloo+Thambi+2&family=Roboto+Mono:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Baloo+Thambi+2&family=Roboto+Mono:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Baloo+Thambi+2&family=Roboto+Mono:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
iframe {
  display: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

*{
    margin: 0;
    padding: 0;
}
.header{
    max-height: 20vh;
    width: 100vw;
    background-color: aqua;
    font-family: 'Baloo Thambi 2', cursive;
}
.header-container{
    max-height: 20vh;
    width: 100vw;
    background-color: aliceblue;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.nav-links{ 
    max-height: 18vh;
    height: 14vh;
    max-width: 100vw;
    width: 100vw;
    
    background-color:aliceblue;
    display: flex;
    justify-content: space-between;
    align-self: center;
    font-family: 'Baloo Thambi 2', cursive;
}
.nav-links ul {
    max-width: 100vw;
    
    align-self: center;
    list-style: none;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    margin-right:2vw ; 
    margin-left: 2vw;
    justify-content: space-around;
}
.nav-links ul a {
    text-decoration: none;
    color: black;

}
.btnn2{
    width: 8vw;
    height: 7vh;
    background-color: #e6ee26 ;
    color: black;
    text-align: center;
    border: none;
    font-family: 'Baloo Thambi 2', cursive;
    font-size: 1rem;
    cursor: pointer;
}

.btnn2:active{
    background-color: yellow;
}


.nav-links ul img{
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    
}
 @media (max-width:600px){
    .nav-links {
        grid-gap: 0.1rem;
        gap: 0.1rem;
    }
    .nav-links  ul{
        margin-right: 10px;
    }
    .btnn2{
        font-size: 0.7rem;
        width: 80px;
        height: 35px;
    }
 }






 .gap{
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
 
}

@media (max-width:1000px){
    #home-img{
        width: 300px;
        height: 300px;
       
    }

    .gap{
        display: flex;
        flex-direction: column;
        grid-gap: 1rem;
        gap: 1rem;
        font-family: 'Changa', sans-serif;
        color: rgb(31, 138, 138);
    }
}
*{
    margin: 0;
    padding: 0;
}
.home-container{
    max-width: 100vw;
    width: 100vw;
    background-color: aliceblue;
   

}

.home-parent{
    max-width: 100vw;
    width: 100vw;
    max-height:200vh;
    display: inline-flex;
    background-color:aliceblue;

}
.home-right{
    max-width: 100vw;
    width: 50vw;
    height: 100vh;
    background-color: aliceblue;
    justify-content: center;
    display: flex;
    align-items: center;
    
}

.right-items{
    width: 20vw;
    height: 40vh;
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
padding-top: 2vh;
justify-content: left;
background-color: aliceblue;




}

.right-items h1{
    font-size: 3.5rem;
    text-align: left;
    font-family: 'Changa', sans-serif;
}
.right-items h2{
    font-size: 1.7rem;
    text-align: left;
    font-family: 'Changa', sans-serif;
}

.right-items button{
    width: 10vw;
    height: 5vh;
    background-color:#e6ee26 ;
    border: 0;
    font-weight: 600;
    display: flex;
   align-items: center;
   justify-content: center;
   
   
   
}


.home-left{
    max-width: 100vw;
    width: 50vw;
    height: 100vh;
    
    justify-content: center;
    display: flex;
    align-items: center;
    
}
.left-items{
    margin-top: 3vh;
    position: relative;
    right:15vw;
    font-family: 'Changa', sans-serif;
}

@media(max-width:450px){
   
}

@media(max-width:700px){
    .home-parent{
        display: flex;
        flex-direction: column;
        width: 100vw;
        height: 120vh;
       
    }
    .home-right{
      width: 100vw;
      
      
     

    }
    .home-left{
        width: 100vw;
        max-width: 100vw;
        height: 80vh;
        
    }
    .home-container{
        height: 150vh;
    }
     
    .right-items{
        width: 50vw;
    
        height: 20vh;
        
    }

    .right-items button{
        
        height: 30px;
    }

}


@media (max-width:1000px){
    .home-parent{
        display: flex;
        height: 110vh;
    }

    .home-right{
        justify-content: center;
        padding-left: 10px;
        align-items: center;
        height: 40vh;
        
    }
 .home-left{
       height: 50vh;
       margin: 20px auto;
      
    }

    .right-items{
        height: 20vh;
    }
   

    .left-items{
        
        position: relative;
        left: 1vw;
        align-items: center;
        height: 40vh;
        justify-content: center;
       
    }
    }

   
    .right-items button{
        width:120px;
    }

   

   
   


*{
    margin: 0;
    padding: 0;
}
.start-container{
margin: 0;
padding: 0;
width: 100vw;
height: 100vh;
background-color: aliceblue;
display: flex;
flex-direction: column;

}

.start-parent{
    margin: 0;
padding: 0;
width: 100vw;

display: flex;
justify-content: center;





}

.start-header{
    margin: 0;
    padding: 0;
    min-width:80vw ;
    max-width: 80vw;
    display: flex;
    flex-direction: column;
  
    justify-content: center;
    
   
}
.start-top-items{
    width: 18vw;
    height: 20vh;
    max-width: 20vw;
    
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    text-align: left;
    padding-left: 10vw;
    padding-top: 2vh;
   
    
}

.start-top-items span{
  
    font-size: 2rem;
    font-family: 'Changa', sans-serif;
color: forestgreen;}

.start-parent-footer{
   
    width: 100vw;
  
    display: flex;
    justify-content: center;
    

}

.start-footer{
    margin: 0;
    padding: 0;
    height:140vh;

  
   
    display: flex;
    flex-direction: column;
   
    align-items: center;
    grid-gap: 3rem;
    gap: 3rem;
    box-sizing: border-box;
}

.search-bar{
    margin-top: 10vh;
    padding: 0;
  
    min-width: 20vw;
    width: 50vw;
    max-height: 10vh;
    height: 8vh;
    display: flex;
   
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    box-sizing: border-box;
    
    
}
.search-bar form{
    background-color: rgb(255, 255, 255);
    width: 600px;
    height: 40px;
    display: flex;
}
.search-bar form input{
    flex: 1 1;
    border: none;
    outline: none;font-family: 'Montserrat', sans-serif;
    background-color: whitesmoke;
    text-align: center;
}
.search-bar  form button{
    background-color: tomato;
    padding: 10px 50px;
    border: none;
    outline: none;
    color: #fff;
    letter-spacing: 1px;
    cursor: pointer;font-family: 'Montserrat', sans-serif;
    box-sizing: border-box;
   
}




.brands-info{
   
    align-items: center;
    display: flex;

    position: relative;
width:80vw;
height: 0;


box-sizing: border-box;
justify-content: center;
flex-wrap: wrap;
}
.main_content{
    
    align-items: center;
    display: flex;
   
   
width:60vw;
height:0;

box-sizing: border-box;
justify-content: space-between;
flex-wrap: wrap;
grid-gap: 2rem;
gap: 2rem;
padding-bottom: 50px ;

}

@media(max-width:1000px){
    .main_content{
        justify-content: center;
        height: 80vh;
    }

    .start-top-items{
        height: 10vh;
    }
}

@media(max-width:700px){
    .start-top-items{
        height: 20vh;
    }
    .search-bar{
        width: 80vw;
    }
    .start-footer{
        height: 150vh;
    }
}



.card_img img{
   width: 20vh;
   height: 20vh;
   box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
}
*{
    margin: 0;
    padding: 0;
    font-family: 'Roboto Mono', monospace;
}

.Memories_card{
    width: 100vw;
    height: 70vw;
    background-color: aliceblue;
    display: flex;
    justify-content: center;
    
    
}
#bid:active{
    background-color: yellow;
    cursor: pointer;
}

.parent-memo{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    height: 60%;    margin-top: 10vh;
}

.header-momo{
    box-sizing: border-box;
    display: flex;
    width: 100%;
    height: 60vh;
    justify-content: left;
    grid-gap: 1rem;
    gap: 1rem;
  
}
.memo-image img{
    width: 25vw;
    height: 45vh;
}
.memo-info{
    display: flex;
    flex-direction: column;
   grid-gap: 1.5rem;
   gap: 1.5rem;
    text-align: left;
   
}
#heading{
    color: #0b1098;
}
.grid-memo{
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
}

.grid-memo img{
    width: 5vw;
    height: 10vh;}
.footer-memo{
    box-sizing: border-box;
    display: flex;
    width: 100%;
    height: 50%;
    justify-content: left;
    
    
    
}
.btn-memo{
    width: 40vw;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    padding-top: 20px;
    
   
}
.footer-left{
    display: flex;
    flex-direction: column;
    width: 60vw;
   
    
    text-align: left;
}
.auction-details{
   
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    margin-left: 10px;
    grid-gap: 1rem;
    gap: 1rem;
    
    
}
.auction-details h3{
    font-weight: 700;
}
.auction-details h5{
    font-weight: 800;
}

.btn-memo button{
    width: 6vw;
    height: 6vh;
    background-color: #e6ee26 ;
    color: black;
    text-align: center;
    border: none;
    font-family: 'Baloo Thambi 2', cursive;
    font-size: 1rem;
    cursor: pointer;
    
}
 .btnn{
    width: 6vw;
    height: 7vh;
    background-color: #e6ee26 ;
    color: black;
    text-align: center;
    border: none;
    font-family: 'Baloo Thambi 2', cursive;
    font-size: 1rem;
}
.btn-memo-parent{

    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}
.item-information{
    display: flex;
  grid-gap: 0rem;
  gap: 0rem;
  padding: 10px;
}
.item-history{
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    text-align: left;
}
.item-history hr{
    width: 10vw;
    border-color: black;
}



@media (max-width:1000px){
    .Memories_card{
        height: 150vh;
    }

    .header-momo{
        height: 30vh;
        width: 80vw;
    }
    .footer-memo{
        width: 80vw;
    }
    .memo-image img{
        height: 300px;
    }
    .grid-memo img{
        height: 50px;
    }
    .btn-memo{
       width: 50vw;
       margin-left: 20px;
    }
    .btn-memo button{
     width: 10vw;
     height: 6vh;
    }
    .btnn{
        width: 10vw;
    }
}

@media(max-width:700px){
   .Memories_card{
    width: 100vw;
   }
   .header-momo{
    width: 100vw;
  
   }
   .footer-memo{
    width: 100vw;
    margin-top: 20px;
   }
   
.memo-image img{
    width: 200px;
    height: 200px;
    padding-left: 20px;
}
.grid-memo{
    grid-gap: 0.3rem;
    gap: 0.3rem;
}
.grid-memo img{
    width: 35px;
    height: 40px;
}

.memo-info{
    grid-gap: 1rem;
    gap: 1rem;
}
.btn-memo button{
    font-size: 0.8rem;
    width: 50px;
    height: 40px;
    margin-left: 4px;
}
.btnn{
    width: 55px;
    height: 40px;
    margin-left: 3px;
    font-size: 0.8rem;
}
.item-history h2{
    font-size: 1.1rem;
}
.item-history h3{
    font-size: 0.8rem;
}
.item-information h2{
    font-size: 1.1rem;
}
.btn-memo{
    grid-gap: 0.5rem;
    gap: 0.5rem;
}
}
.modalBackground {
    width: auto;
    height: auto;
    background-color: rgba(200, 200, 200);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
  

  }
  
  .modalContainer8 {
    width: 300px;
    height: 300px;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
 
   
    font-family: 'Roboto Mono', monospace;
    
  }
  
  .modalContainer8 .title2 {
    display: inline-block;
    text-align: center;
   
    color: cadetblue;
  }
  
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
  }
  
  .titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
  }
  
  .modalContainer8 .body51 {
    flex: 100% 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.5rem;
    text-align: center;
    flex-direction: column;
    border: none;
   
   
   
    
  }
.body51 p{
    font-size: 1.5rem;
}
 
.modalBackground {
    width: auto;
    height: auto;
    background-color: rgba(200, 200, 200);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;

  }
  
  .modalContainer3 {
    width: 300px;
    height: 300px;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
    font-family: 'Roboto Mono', monospace;
  }
  
  .modalContainer3 .title {
    display: inline-block;
    text-align: center;
    margin-top: 5px;
  }
  
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
  }
  
  .titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
  }
  
  .modalContainer3 .body3 {
    flex: 20% 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    text-align: center;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
  }
  .body3 input{
    background-color: #e6ee26;
    color: black;
    outline: none;
    border-radius: 5px;
    font-weight: 700;
    text-align: center;
    height: 4vh;
    font-size: 1rem;
    border: none;
  }
  
  
  .modalContainer3 .footer {
    flex: 20% 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContainer3 .footer button {
    width: 150px;
    height: 45px;
    margin: 10px;
    border: none;
    background-color:#e6ee26;
    color: black;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
  }
  
  #cancelBtn {
    background-color: #e6ee26;
  }
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Josefin Sans', sans-serif;
}

.user-container{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    background-color: aliceblue;
    min-height: 100vh;
    
    

}

.user-parent{
    width: 60%;
    height: 100vh;
    
align-items: center; justify-content: center;
    display: flex;
    flex-direction: column;

    
}

.user-header{
   margin-top: 10vh; 
    width: 60vw;
  
    box-sizing: border-box;
    display: flex;
    grid-gap: 3rem;
    gap: 3rem;
    height: 60vh;
}

.user-header img{
    width: 22vw;
    height: 45vh;
    
}
.user-header-right{
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
}

.user-header-right button{
    height: 5vh;
    font-size: 1.1rem;
    background-color: #e6ee26;
    color: black;
    border: none;
    font-weight: 700;
    width:100px;
    cursor: pointer;
}
.user-header-right button:active{
background-color: yellow;
}
.user-footer
{
    width: 100%;
    height: 40vh;
   
    display: flex;
    text-align: left;
    
}

.user-footer-left{
    width: 50%;
    height: 40vh;
  
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}
.small-img{
    width: 4vw;
    height: 8vh;}

.footer-left1{
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}
.footer-right1{
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
}
.footer-right1 sup{
    cursor: pointer;
    
}
.user-footer-right{
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
}

@media(max-width:1024px){
    .user-container{
        height: 100vh;
    }
    .user-parent{
        height: 70vh;
    }
    .user-header{
        height: 25vh;
    }
    .user-header-right{
        height: 20vh;
    }
    .user-header-right button{
        height: 30px;
    }
    .user-header-left img{
        height: 250px;
        width: 250px;
    }
    .user-footer-left{
        height: 20vh;
        padding-top: 5px;

    }
    .user-footer-left img{
        height: 50px;
        width: 40px;
    }
    .user-footer{
        height: 20vh;
    }
    .user-footer-right{
        padding-top: 5px;
    }
}

@media(max-width:700px){
    .user-parent{
        width: 90vw;
    }
    .user-header{
        width: 90vw;
    }
    .user-footer-left{
        padding-top: 10px;
    }
    .user-footer-right{
        padding-top: 10px;
    }
}

@media (max-width:450px){
    .user-header-left img{
        width: 150px;
        height: 150px;
    }
    .user-header{
        grid-gap: 0.5rem;
        gap: 0.5rem;
    }
    .user-footer-left h1{
        font-size: 1.5rem;
    }
    .footer1-right h2{
        font-size: 1.1rem;
    }
    .footer1-right h3{
        font-size: 0.8rem;
    }
    .user-footer-right h1{
        font-size: 1.5rem;
    }
    .footer-right1 img{
        -webkit-transform: scale(0.7);
                transform: scale(0.7);
    }
    .footer-right1 h2{
        font-size: 1.1rem;
    }
    .user-footer-right{
        grid-gap: 1.5rem;
        gap: 1.5rem;
    }
    .footer-right1{
        grid-gap: 1rem;
        gap: 1rem;
    }
}
.modalBackground {
    width: auto;
    height: auto;
    background-color: rgba(200, 200, 200);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;

  }
  
  .modalContainer9 {
    width: 350px;
    height: 350px;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
    font-family: 'Roboto Mono', monospace;
  }
  
  .modalContainer9 .title {
    display: inline-block;
    text-align: center;
    margin-top: 10px;
    color: cadetblue;
  }
  
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
  }
  
  .titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
  }
  
  .modalContainer9 .body6 {
    flex: 100% 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.5rem;
    text-align: center;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
  }
.body6 p{
    font-size: 1.5rem;
}
 
.modalBackground {
  width: auto;
  height: auto;
  background-color: rgba(200, 200, 200);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}
.modalContainer .body .datepicker {
  color: black;
}

.modalContainer {
  width: 400px;
  height: 400px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  font-family: "Roboto Mono", monospace;
  padding-top: 0;
}

.modalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 5px;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modalContainer .body {
  flex: 100% 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.5rem;
  text-align: center;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}
.body input {
  background-color: #e6ee26;
  color: black;
  outline: none;
  border-radius: 5px;
  font-weight: 700;
  text-align: center;
  height: 4vh;
  font-size: 1rem;
  border: none;
}

.modalContainer .footer {
  flex: 20% 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer .footer button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: #e6ee26;
  color: black;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}

#cancelBtn {
  background-color: #e6ee26;
}

.modal_backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.transaction_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 3px;
  max-width: 560px;
  padding: 3.2rem;
  width: 100%;
}

.transaction_modal-content {
  display: flex;
  flex-direction: column;
  grid-gap: 3.2rem;
  gap: 3.2rem;
}

@media (max-width: 500px) {
  .modalContainer {
    width: 350px;
    height: 350px;
  }
  .modalContainer .title {
    margin-top: 0;
  }
  .modalContainer .body {
    grid-gap: 0.4rem;
    gap: 0.4rem;
  }
}

*{
    padding: 0;
    margin: 0;
}
.mint-container{
    width: 100vw;
    height: 100vh;
    background-color: aliceblue;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    font-size: 1.2rem;
    
}
.mint-parent{
    width: 50vw;
    height: 50vh;
    margin-top: 80px;
    min-height: 50vh;
}

.mint-header{
    width: 50vw;
    height: 25vh;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
}
.header-left{
    width: 25vw;
    height: 25vh;
    background-color: gray;
}

.header-right{
    width: 25vw;
    height: 25vh;
    text-align: left;
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.header-right input{
    width: 10vw;
    height: 5vh;
    border: none;
    outline: none;
    font-size: 1.1rem;
    
}
.header-right form select{
    width: 10vw;
    height: 5vh;
    border: none;
    outline: none;
    font-size: 1.2rem;
   
}

.mint-footer{
    width: 50vw;
    height: 25vh;
    display: flex;
    text-align: left;
    margin-top: 20px;
}

.footer-left{
    width: 25vw;
    height: 25vh;
}

.footer-right{
    width: 25vw;
    height: 25vh;
    display: flex;
    flex-direction:column ;
    grid-gap: 1rem;
    gap: 1rem;
}

.footer-right textarea{
    width: 15vw;
    height: 17vh;
    border: none;
    outline: none;
    font-size: 1.2rem;

}

.mint-btn{
    width: 7vw;
    height: 5vh;
    background-color: #e6ee26;
    text-align: center;
    color: black;
    border: none;
    font-size: 1rem;
    outline: none;
    font-size: 1.2rem;
    cursor: pointer;
    border-radius: 5px;
}

.mint-btn:active{
    background-color: yellow ;
}
.btnn{
    cursor: pointer;
    border-radius: 5px;
}

.btnn:active{
    background-color: yellow;

}

@media(max-width:1000px){

.header-right input{
    width: 100px;
}
.header-right form select{
    width: 100px;
}
.footer-right textarea{
    width: 200px;
    height: 200px;
}
.footer-right button{
    width: 80px;
}
.header-left{
    width: 60vw;
    height: 20vh;
}
.mint-parent{
    width: 70vw;
}
.header-right{
    width: 40vw;
}
.mint-header{
    width: 70vw;
}
.mint-footer{
    width: 80vw;
margin-top: 0;
}
.footer-left{
    width: 30vw;
}
}
@media(max-width:600){
    .mint-parent{
        width: 90vw;
    }
    .footer-left{
        width: 25vw;
    }
    .footer-right textarea{
        width: 50px;
    }
    .mint-header{
        width: 90vw;
    }
    .mint-footer{
        width: 90vw;
       
    }
    .footer-left{
        width: 1vw;
    }
    
}

*{
    margin: 0;
    padding: 0;
    font-family: 'Roboto Mono', monospace;
}

.Memories_card{
    width: 100vw;
    height: 70vw;
    background-color: aliceblue;
    display: flex;
    justify-content: center;
    
    
}

.parent-memo{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    height: 60%;    margin-top: 10vh;
}

.header-momo{
    box-sizing: border-box;
    display: flex;
    width: 100%;
    height: 60vh;
    justify-content: left;
    grid-gap: 1rem;
    gap: 1rem;
  
}
.memo-image img{
    width: 25vw;
    height: 45vh;
}
.memo-info{
    display: flex;
    flex-direction: column;
   grid-gap: 1.5rem;
   gap: 1.5rem;
    text-align: left;
   
}
#heading{
    color: #0b1098;
}
.grid-memo{
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
}

.grid-memo img{
    width: 5vw;
    height: 10vh;}
.footer-memo{
    box-sizing: border-box;
    display: flex;
    width: 100%;
    height: 50%;
    justify-content: left;
    
    
}
.btn-memo{
    width: 40vw;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    
   
   
}
.btn-memo button{
    width: 6vw;
    height: 8vh;
    background-color: #e6ee26 ;
    color: black;
    text-align: center;
    border: none;
    font-family: 'Baloo Thambi 2', cursive;
    font-size: 1rem;
    cursor: pointer;
   
    
}
.btn-memo button:active{
    background-color: yellow;
}
 .btnn{
    width: 6vw;
    height: 7vh;
    background-color: #e6ee26 ;
    color: black;
    text-align: center;
    border: none;
    font-family: 'Baloo Thambi 2', cursive;
    font-size: 1rem;
}
.btn-memo-parent{

    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}
.item-information{
    display: flex;
  grid-gap: 0rem;
  gap: 0rem;
  padding: 10px;
}
.item-history{
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    text-align: left;
}
.item-history hr{
    width: 10vw;
    border-color: black;
}



@media (max-width:1000px){
    .Memories_card{
        height: 150vh;
    }

    .header-momo{
        height: 30vh;
        width: 80vw;
    }
    .footer-memo{
        width: 80vw;
    }
    .memo-image img{
        height: 300px;
    }
    .grid-memo img{
        height: 50px;
    }
    .btn-memo{
       width: 50vw;
    }
    .btn-memo button{
     width: 10vw;
     height: 5vh;
    }
    .btnn{
        width: 10vw;
    }
}

@media(max-width:700px){
   .Memories_card{
    width: 100vw;
   }
   .header-momo{
    width: 100vw;
  
   }
   .footer-memo{
    width: 100vw;
    margin-top: 20px;
   }
   
.memo-image img{
    width: 200px;
    height: 200px;
    padding-left: 20px;
}
.grid-memo{
    grid-gap: 0.3rem;
    gap: 0.3rem;
}
.grid-memo img{
    width: 35px;
    height: 40px;
}

.memo-info{
    grid-gap: 1rem;
    gap: 1rem;
}
.btn-memo button{
    font-size: 0.8rem;
    width: 50px;
    height: 40px;
    margin-left: 4px;
}
.btnn{
    width: 55px;
    height: 40px;
    margin-left: 3px;
    font-size: 0.8rem;
}
.item-history h2{
    font-size: 1.1rem;
}
.item-history h3{
    font-size: 0.8rem;
}
.item-information h2{
    font-size: 1.1rem;
}
.btn-memo{
    grid-gap: 0.5rem;
    gap: 0.5rem;
}
}
.modalBackground {
    width: auto;
    height: auto;
    background-color: rgba(200, 200, 200);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;

  }
  
  .modalContainer8 {
    width: 300px;
    height: 300px;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
    font-family: 'Roboto Mono', monospace;
  }
  
  .modalContainer8 .title {
    display: inline-block;
    text-align: center;
    margin-top: 5px;
    color: rgb(34, 95, 46);
  }
  
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
  }
  
  .titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
  }
  
  .modalContainer8 .body5 {
    flex: 100% 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.5rem;
    text-align: center;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    border: 1px solid black;
  }
.body5 p{
    font-size: 1.5rem;
}

.body5 button{
    width: 100px;
    height: 30px;
    background-color: #e6ee26;
    color: black;
    font-size: 1rem;
    border-radius: 6px;
    text-align: center;
    font-family: 'Roboto Mono', monospace;

}
 
*{
    margin: 0;
    padding: 0;
    font-family: 'Roboto Mono', monospace;
}

.Memories_card{
    width: 100vw;
    height: 70vw;
    background-color: aliceblue;
    display: flex;
    justify-content: center;
    
    
}

.parent-memo{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    height: 60%;    margin-top: 10vh;
}

.header-momo{
    box-sizing: border-box;
    display: flex;
    width: 100%;
    height: 60vh;
    justify-content: left;
    grid-gap: 1rem;
    gap: 1rem;
  
}
.memo-image img{
    width: 25vw;
    height: 45vh;
}
.memo-info{
    display: flex;
    flex-direction: column;
   grid-gap: 1.5rem;
   gap: 1.5rem;
    text-align: left;
   
}
#heading{
    color: #0b1098;
}
.grid-memo{
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
}

.grid-memo img{
    width: 5vw;
    height: 10vh;}
.footer-memo{
    box-sizing: border-box;
    display: flex;
    width: 100%;
    height: 50%;
    justify-content: left;
    
    
}
.btn-memo{
    width: 40vw;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    
   
   
}
.btn-memo button{
    width: 6vw;
    height: 8vh;
    background-color: #e6ee26 ;
    color: black;
    text-align: center;
    border: none;
    font-family: 'Baloo Thambi 2', cursive;
    font-size: 1rem;
   
    
}
 .btnn{
    width: 6vw;
    height: 7vh;
    background-color: #e6ee26 ;
    color: black;
    text-align: center;
    border: none;
    font-family: 'Baloo Thambi 2', cursive;
    font-size: 1rem;
}
.btn-memo-parent{

    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}
.item-information{
    display: flex;
  grid-gap: 0rem;
  gap: 0rem;
  padding: 10px;
}
.item-history{
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    text-align: left;
}
.item-history hr{
    width: 10vw;
    border-color: black;
}

#add:active{
 background-color: yellow;
}



@media (max-width:1000px){
    .Memories_card{
        height: 150vh;
    }

    .header-momo{
        height: 30vh;
        width: 80vw;
    }
    .footer-memo{
        width: 80vw;
    }
    .memo-image img{
        height: 300px;
    }
    .grid-memo img{
        height: 50px;
    }
    .btn-memo{
       width: 50vw;
    }
    .btn-memo button{
     width: 10vw;
     height: 5vh;
    }
    .btnn{
        width: 10vw;
    }
}

@media(max-width:700px){
   .Memories_card{
    width: 100vw;
   }
   .header-momo{
    width: 100vw;
  
   }
   .footer-memo{
    width: 100vw;
    margin-top: 20px;
   }
   
.memo-image img{
    width: 200px;
    height: 200px;
    padding-left: 20px;
}
.grid-memo{
    grid-gap: 0.3rem;
    gap: 0.3rem;
}
.grid-memo img{
    width: 35px;
    height: 40px;
}

.memo-info{
    grid-gap: 1rem;
    gap: 1rem;
}
.btn-memo button{
    font-size: 0.8rem;
    width: 50px;
    height: 40px;
    margin-left: 4px;
}
.btnn{
    width: 55px;
    height: 40px;
    margin-left: 3px;
    font-size: 0.8rem;
}
.item-history h2{
    font-size: 1.1rem;
}
.item-history h3{
    font-size: 0.8rem;
}
.item-information h2{
    font-size: 1.1rem;
}
.btn-memo{
    grid-gap: 0.5rem;
    gap: 0.5rem;
}
}
.modalBackground {
    width: auto;
    height: auto;
    background-color: rgba(200, 200, 200);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;

  }
  
  .modalContainer10 {
    width: 350px;
    height: 350px;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
    font-family: 'Roboto Mono', monospace;
  }
  
  .modalContainer10 .title {
    display: inline-block;
    text-align: center;
    margin-top: 5px;
    color: cadetblue;
  }
  
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
  }
  
  .titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
  }
  
  .modalContainer10 .body7 {
    flex: 100% 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    text-align: center;
   
    grid-gap: 1rem;
   
    gap: 1rem;
  }
  .body-part-btn{
    display: flex;
    grid-gap :1rem;
    gap :1rem;
  }
  .btnn1{
    width: 6vw;
    height: 7vh;
    background-color: silver ;
    color: black;
    text-align: center;
    border: none;
    font-family: 'Baloo Thambi 2', cursive;
    font-size: 1rem;
}



 
