

 .gap{
    display: flex;
    flex-direction: column;
    gap: 1rem;
 
}

@media (max-width:1000px){
    #home-img{
        width: 300px;
        height: 300px;
       
    }

    .gap{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        font-family: 'Changa', sans-serif;
        color: rgb(31, 138, 138);
    }
}