@import url('https://fonts.googleapis.com/css2?family=Baloo+Thambi+2&family=Roboto+Mono:wght@300&display=swap');

.modalBackground {
    width: auto;
    height: auto;
    background-color: rgba(200, 200, 200);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;

  }
  
  .modalContainer3 {
    width: 300px;
    height: 300px;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
    font-family: 'Roboto Mono', monospace;
  }
  
  .modalContainer3 .title {
    display: inline-block;
    text-align: center;
    margin-top: 5px;
  }
  
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
  }
  
  .titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
  }
  
  .modalContainer3 .body3 {
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    text-align: center;
    flex-direction: column;
    gap: 1rem;
  }
  .body3 input{
    background-color: #e6ee26;
    color: black;
    outline: none;
    border-radius: 5px;
    font-weight: 700;
    text-align: center;
    height: 4vh;
    font-size: 1rem;
    border: none;
  }
  
  
  .modalContainer3 .footer {
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContainer3 .footer button {
    width: 150px;
    height: 45px;
    margin: 10px;
    border: none;
    background-color:#e6ee26;
    color: black;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
  }
  
  #cancelBtn {
    background-color: #e6ee26;
  }