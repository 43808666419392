@import url('https://fonts.googleapis.com/css2?family=Baloo+Thambi+2&family=Josefin+Sans:wght@300&family=Roboto+Mono:wght@300&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Josefin Sans', sans-serif;
}

.user-container{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    background-color: aliceblue;
    min-height: 100vh;
    
    

}

.user-parent{
    width: 60%;
    height: 100vh;
    
align-items: center; justify-content: center;
    display: flex;
    flex-direction: column;

    
}

.user-header{
   margin-top: 10vh; 
    width: 60vw;
  
    box-sizing: border-box;
    display: flex;
    gap: 3rem;
    height: 60vh;
}

.user-header img{
    width: 22vw;
    height: 45vh;
    
}
.user-header-right{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.user-header-right button{
    height: 5vh;
    font-size: 1.1rem;
    background-color: #e6ee26;
    color: black;
    border: none;
    font-weight: 700;
    width:100px;
    cursor: pointer;
}
.user-header-right button:active{
background-color: yellow;
}
.user-footer
{
    width: 100%;
    height: 40vh;
   
    display: flex;
    text-align: left;
    
}

.user-footer-left{
    width: 50%;
    height: 40vh;
  
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.small-img{
    width: 4vw;
    height: 8vh;}

.footer-left1{
  display: flex;
  gap: 1rem;
}
.footer-right1{
    display: flex;
    gap: 1rem;
}
.footer-right1 sup{
    cursor: pointer;
    
}
.user-footer-right{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

@media(max-width:1024px){
    .user-container{
        height: 100vh;
    }
    .user-parent{
        height: 70vh;
    }
    .user-header{
        height: 25vh;
    }
    .user-header-right{
        height: 20vh;
    }
    .user-header-right button{
        height: 30px;
    }
    .user-header-left img{
        height: 250px;
        width: 250px;
    }
    .user-footer-left{
        height: 20vh;
        padding-top: 5px;

    }
    .user-footer-left img{
        height: 50px;
        width: 40px;
    }
    .user-footer{
        height: 20vh;
    }
    .user-footer-right{
        padding-top: 5px;
    }
}

@media(max-width:700px){
    .user-parent{
        width: 90vw;
    }
    .user-header{
        width: 90vw;
    }
    .user-footer-left{
        padding-top: 10px;
    }
    .user-footer-right{
        padding-top: 10px;
    }
}

@media (max-width:450px){
    .user-header-left img{
        width: 150px;
        height: 150px;
    }
    .user-header{
        gap: 0.5rem;
    }
    .user-footer-left h1{
        font-size: 1.5rem;
    }
    .footer1-right h2{
        font-size: 1.1rem;
    }
    .footer1-right h3{
        font-size: 0.8rem;
    }
    .user-footer-right h1{
        font-size: 1.5rem;
    }
    .footer-right1 img{
        transform: scale(0.7);
    }
    .footer-right1 h2{
        font-size: 1.1rem;
    }
    .user-footer-right{
        gap: 1.5rem;
    }
    .footer-right1{
        gap: 1rem;
    }
}