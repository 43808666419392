@import url('https://fonts.googleapis.com/css2?family=Baloo+Thambi+2&family=Roboto+Mono:wght@300&display=swap');

*{
    margin: 0;
    padding: 0;
    font-family: 'Roboto Mono', monospace;
}

.Memories_card{
    width: 100vw;
    height: 70vw;
    background-color: aliceblue;
    display: flex;
    justify-content: center;
    
    
}
#bid:active{
    background-color: yellow;
    cursor: pointer;
}

.parent-memo{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    height: 60%;    margin-top: 10vh;
}

.header-momo{
    box-sizing: border-box;
    display: flex;
    width: 100%;
    height: 60vh;
    justify-content: left;
    gap: 1rem;
  
}
.memo-image img{
    width: 25vw;
    height: 45vh;
}
.memo-info{
    display: flex;
    flex-direction: column;
   gap: 1.5rem;
    text-align: left;
   
}
#heading{
    color: #0b1098;
}
.grid-memo{
    display: flex;
    gap: 1rem;
}

.grid-memo img{
    width: 5vw;
    height: 10vh;}
.footer-memo{
    box-sizing: border-box;
    display: flex;
    width: 100%;
    height: 50%;
    justify-content: left;
    
    
    
}
.btn-memo{
    width: 40vw;
    display: flex;
    gap: 1rem;
    padding-top: 20px;
    
   
}
.footer-left{
    display: flex;
    flex-direction: column;
    width: 60vw;
   
    
    text-align: left;
}
.auction-details{
   
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    margin-left: 10px;
    gap: 1rem;
    
    
}
.auction-details h3{
    font-weight: 700;
}
.auction-details h5{
    font-weight: 800;
}

.btn-memo button{
    width: 6vw;
    height: 6vh;
    background-color: #e6ee26 ;
    color: black;
    text-align: center;
    border: none;
    font-family: 'Baloo Thambi 2', cursive;
    font-size: 1rem;
    cursor: pointer;
    
}
 .btnn{
    width: 6vw;
    height: 7vh;
    background-color: #e6ee26 ;
    color: black;
    text-align: center;
    border: none;
    font-family: 'Baloo Thambi 2', cursive;
    font-size: 1rem;
}
.btn-memo-parent{

    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.item-information{
    display: flex;
  gap: 0rem;
  padding: 10px;
}
.item-history{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    text-align: left;
}
.item-history hr{
    width: 10vw;
    border-color: black;
}



@media (max-width:1000px){
    .Memories_card{
        height: 150vh;
    }

    .header-momo{
        height: 30vh;
        width: 80vw;
    }
    .footer-memo{
        width: 80vw;
    }
    .memo-image img{
        height: 300px;
    }
    .grid-memo img{
        height: 50px;
    }
    .btn-memo{
       width: 50vw;
       margin-left: 20px;
    }
    .btn-memo button{
     width: 10vw;
     height: 6vh;
    }
    .btnn{
        width: 10vw;
    }
}

@media(max-width:700px){
   .Memories_card{
    width: 100vw;
   }
   .header-momo{
    width: 100vw;
  
   }
   .footer-memo{
    width: 100vw;
    margin-top: 20px;
   }
   
.memo-image img{
    width: 200px;
    height: 200px;
    padding-left: 20px;
}
.grid-memo{
    gap: 0.3rem;
}
.grid-memo img{
    width: 35px;
    height: 40px;
}

.memo-info{
    gap: 1rem;
}
.btn-memo button{
    font-size: 0.8rem;
    width: 50px;
    height: 40px;
    margin-left: 4px;
}
.btnn{
    width: 55px;
    height: 40px;
    margin-left: 3px;
    font-size: 0.8rem;
}
.item-history h2{
    font-size: 1.1rem;
}
.item-history h3{
    font-size: 0.8rem;
}
.item-information h2{
    font-size: 1.1rem;
}
.btn-memo{
    gap: 0.5rem;
}
}