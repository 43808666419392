@import url("https://fonts.googleapis.com/css2?family=Baloo+Thambi+2&family=Roboto+Mono:wght@300&display=swap");

.modalBackground {
  width: auto;
  height: auto;
  background-color: rgba(200, 200, 200);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}
.modalContainer .body .datepicker {
  color: black;
}

.modalContainer {
  width: 400px;
  height: 400px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  font-family: "Roboto Mono", monospace;
  padding-top: 0;
}

.modalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 5px;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modalContainer .body {
  flex: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.5rem;
  text-align: center;
  flex-direction: column;
  gap: 1rem;
}
.body input {
  background-color: #e6ee26;
  color: black;
  outline: none;
  border-radius: 5px;
  font-weight: 700;
  text-align: center;
  height: 4vh;
  font-size: 1rem;
  border: none;
}

.modalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer .footer button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: #e6ee26;
  color: black;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}

#cancelBtn {
  background-color: #e6ee26;
}

.modal_backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.transaction_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 3px;
  max-width: 560px;
  padding: 3.2rem;
  width: 100%;
}

.transaction_modal-content {
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
}

@media (max-width: 500px) {
  .modalContainer {
    width: 350px;
    height: 350px;
  }
  .modalContainer .title {
    margin-top: 0;
  }
  .modalContainer .body {
    gap: 0.4rem;
  }
}
